import React, { useState } from 'react';
import { Alert, Button } from 'reactstrap';

import { LeaveEntitlementModal } from './LeaveEntitlementModal';

const LeaveOfAbsenceTable = props => {
  const [showDetailsModal, setDetailsModal] = useState(false);
  const [selectedRecordID, setSelectedRecordID] = useState(undefined);

  const setRecord = async recordId => {
    setSelectedRecordID(recordId);
    setDetailsModal(true);
  };

  return (
    <>
      {props.leaveRequests && (
        <table className="table table-sm">
          <thead>
            <tr>
              <th width="15%">Name</th>
              <th width="20%">Title</th>
              <th width="15%">Leave Type</th>
              <th width="10%">LOA Start</th>
              <th width="10%">LOA End</th>
              <th width="15%">Return to Duty</th>
              <th width="15%">Leave Entitlement</th>
            </tr>
          </thead>
          <tbody>
            {props.leaveRequests.map(leaveRequest => (
              <tr key={leaveRequest.record_id_}>
                <td>{leaveRequest.full_name}</td>
                <td>{leaveRequest.employee_id___title}</td>
                <td>{leaveRequest.leave_of_absence_period_type__for_letter_}</td>
                <td>{leaveRequest.approved_leave_start_date}</td>
                <td>{leaveRequest.approved_leave_end_date}</td>
                <td>{leaveRequest.approved_leave_return_to_duty_date}</td>
                <td>
                  <Button size="sm" onClick={() => setRecord(leaveRequest.record_id_)}>
                    View Details
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!props.leaveRequests && (
        <Alert color="info">
          There don't appear to be any staff members on leave or who recently returned from leave.
        </Alert>
      )}
      {showDetailsModal && (
        <LeaveEntitlementModal
          toggle={() => setDetailsModal(false)}
          selectedRecordID={selectedRecordID}
        />
      )}
    </>
  );
};

export { LeaveOfAbsenceTable };
