import React from 'react';
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <Row>
    <Col sm={{ size: 8, offset: 2 }}>
      <Card>
        <CardBody>
          <CardTitle className="text-center">Page Not Found</CardTitle>
          <p>
            Uh oh! We couldn't find the page you were looking for! Please double-check the link you
            followed, or navigate to the <Link to="/">home</Link> page to find what you're looking
            for.
          </p>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

export { NotFound };
