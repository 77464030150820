import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Button, Alert } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { LoadingSpinner, TextField } from 'Components';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .email('Invalid email address')
    .matches(/dc.gov$/, 'Please enter a DCPS email address'),
  password: Yup.string().required('Required')
});

const Login = ({ history, signIn, signOut, user }) => {
  useEffect(() => {
    if (user) signOut();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [saveErrors, setSaveErrors] = useState(undefined);
  const nextURL = new URL(window.location).searchParams.get('next');

  const handleSubmit = async ({ email, password }, actions) => {
    const response = await signIn(email, password);
    if (response.user) {
      if (nextURL) {
        history.push(nextURL);
      } else {
        history.push('/');
      }
    } else {
      setSaveErrors(response.error);
      actions.setSubmitting(false);
    }
  };

  return (
    <Row>
      <Col lg={{ size: 6, offset: 3 }}>
        <Card>
          <CardBody>
            <CardTitle tag="h5" className="text-center">
              Sign In
            </CardTitle>
            {nextURL && <Alert color="warning">Please sign in to access that page.</Alert>}
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={LoginSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, submitCount, errors }) => (
                <Form noValidate>
                  <Field component={TextField} name="email" label="DCPS Email" />
                  <Field component={TextField} type="password" name="password" label="Password" />
                  <Row>
                    <Col xs="3">
                      <Button type="submit">
                        {isSubmitting ? <LoadingSpinner color="white" size={10} /> : 'Submit'}
                      </Button>
                    </Col>
                    <Col xs="9">
                      {!isSubmitting &&
                        submitCount > 0 &&
                        errors &&
                        Object.keys(errors).length !== 0 && (
                          <p className="text-danger small">
                            There are some issues with your username and/or password. Review and fix
                            any fields outlined in red then try to sign-in again.
                          </p>
                        )}
                      {saveErrors && (
                        <p className="text-danger small">
                          {saveErrors}. Please make sure you are using your exact DCPS email address
                          and password and try submitting again.
                        </p>
                      )}
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export { Login };
