import React from 'react';
import { FormGroup, Label, FormText } from 'reactstrap';
import classnames from 'classnames';

const CheckboxField = ({ field, form: { touched, errors }, ...props }) => {
  const classes = classnames('form-check-input', {
    'is-invalid': errors[field.name] && touched[field.name]
  });
  return (
    <FormGroup check>
      <input
        type="checkbox"
        {...field}
        {...props}
        className={classes}
        id={field.name}
        checked={field.value}
      />
      <Label check for={field.name}>
        {props.label}
      </Label>
      {props.helptext && <FormText>{props.helptext}</FormText>}
      <div className="invalid-feedback">{errors[field.name]}</div>
    </FormGroup>
  );
};

export { CheckboxField };
