import { useState } from 'react';

const useFormWizard = () => {
  const [formValues, setFormValues] = useState({});
  const [currentPage, setCurrentPage] = useState(0);

  const setPageValues = initialValues => {
    return Object.assign(initialValues, formValues);
  };

  const updateValues = (updatedValues, nextStepOverride) => {
    window.scrollTo(0, 0);
    const newFormValues = Object.assign(formValues, updatedValues);
    setFormValues(newFormValues);
    setCurrentPage(nextStepOverride || currentPage + 1);
  };

  const goBack = goBackOverride => {
    window.scrollTo(0, 0);
    setCurrentPage(goBackOverride || currentPage - 1);
  };

  return { formValues, currentPage, setPageValues, updateValues, goBack };
};

export { useFormWizard };
