import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

import { UserNav } from './UserNav';
import { NavBarLogo } from './NavBarLogo';
import Cookies from 'js-cookie';
import './navigation.css';

const Navigation = ({ user, signIn, signOut, getUserInfo }) => {
  const [isOpen, setOpen] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const ticket = queryParams.get('ticket');
  const email = queryParams.get('email');

  if (ticket && !user) {
    getUserInfo(ticket, email);
    queryParams.delete('ticket');
    console.log(Cookies.get());
  }

  // useEffect(() => {

  //   console.log(Cookies.get());
  //   console.log('useEffect called in Navigation');
  // });

  const toggle = () => setOpen(!isOpen);
  const closeNavbar = () => {
    if (isOpen) setOpen(false);
  };

  const role = user ? user.role : undefined;
  const canAccessCentral = role === 'Central Office' || role === 'Administrator';
  const canAccessSchools = role === 'School Leader' || role === 'Administrator';
  return (
    <Navbar color="light" light expand="md" className="border rounded">
      <NavBarLogo />
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-1" navbar>
          <NavItem>
            <NavLink tag={Link} to="/">
              Home
            </NavLink>
          </NavItem>
          {user && (
            <>
              {canAccessCentral && (
                <NavItem>
                  <NavLink tag={Link} onClick={closeNavbar} to="/central">
                    Central Office
                  </NavLink>
                </NavItem>
              )}
              {canAccessSchools && (
                <NavItem>
                  <NavLink tag={Link} onClick={closeNavbar} to="/schools">
                    School Leaders
                  </NavLink>
                </NavItem>
              )}
            </>
          )}
        </Nav>
        <UserNav user={user} closeNavbar={closeNavbar} signIn={signIn} signOut={signOut} />
      </Collapse>
    </Navbar>
  );
  // }
};

export { Navigation };
