import { Parser } from 'xml2js';

/** Converts an attachment to binary text for upload */
const fileToText = inputFile => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result.split(',')[1]);
    };
    temporaryFileReader.readAsDataURL(inputFile);
  });
};

/** Builds and returns XML from JSON object */
const buildRequestBody = async data => {
  return Promise.all(
    Object.keys(data).map(async fieldname => {
      const ret = {
        field: {
          $: {
            name: fieldname
          }
        }
      };
      if (typeof data[fieldname] === 'object' && data[fieldname].name) {
        // We assume that this type of object is a file attachment.
        ret.field.$.filename = data[fieldname].name;
         if (data[fieldname].size !== 0) {
        ret.field._ = await fileToText(data[fieldname]);
         }
      } else if(typeof data[fieldname]!= 'undefined' && typeof data[fieldname].getFullYear === 'function'){
        ret.field._ = data[fieldname].getFullYear() +'-'+(data[fieldname].getMonth()+1) + '-' + data[fieldname].getDate();
      }else{
       ret.field._ = data[fieldname];
      }
      
       return ret;
    })
  );
};

/** Given an object or list of objects, this function always returns a list of objects. */
const forceToArray = objectList => {
  if (Array.isArray(objectList)) return objectList;
  return [objectList];
};

/** Checks localStorage for the user's current quickbase Ticket */
const getUserTicket = () => {
  if (localStorage.getItem('user')) {
    try {
      return JSON.parse(localStorage.getItem('user')).quickbaseTicket;
    } catch (e) {
      return undefined;
    }
  }
};

/** Wrap parseString in a promise for easier-to-read code below. */
const parseResults = body => {
  const parser = new Parser({ explicitArray: false });
  return new Promise((resolve, reject) => {
    parser.parseString(body, (err, result) => {
      if (err) return reject(err);
      resolve(result.qdbapi);
    });
  });
};

export { buildRequestBody, forceToArray, getUserTicket, parseResults };
