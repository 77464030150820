import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import { UserContext } from 'user-context';

export const PrivateRoute = ({ component: Component, title, render, path, exact, ...props }) => {
  // If passed a title, set it using DocumentTitle component; otherwise construct a fragment.
  let TitleComponent = React.Fragment;
  let titleProps = {};
  if (title) {
    TitleComponent = DocumentTitle;
    titleProps = { title: `OTC > ${title}` };
  }

  // If there's no user, redirect to the sign-in page. Otherwise, check if we were passed
  // a render function, and use that or create one based on the component prop.
  const user = useContext(UserContext);
  // if (!user) render = () => <Redirect to={`/login?next=${window.location.pathname}`} />;
  if (!user) render = () => <Redirect to={`/`} />;
  if (render === undefined) render = routerProps => <Component {...routerProps} {...props} />;
  return (
    <TitleComponent {...titleProps}>
      <Route exact={exact} path={path} {...{ render }} />
    </TitleComponent>
  );
};
