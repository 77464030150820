import React from 'react';
import { LoadingSpinner } from 'Components';

import { LeaveOfAbsenceTable } from 'Components/LeaveOfAbsenceTable';
import { useQBQuery } from 'Hooks';

const ReviewLeave = props => {
  const leaveRequests = useQBQuery({
    tableID: 'bi6vn2cfb',
    qid: 47,
    variables: props.user.email
  });
  return (
    <>
      <LoadingSpinner className="text-center mt-5" loading={leaveRequests === undefined} />
      {leaveRequests !== undefined && (
        <>
          <h4 className="border-bottom pb-2 mb-4 mt-5">Leave of Absence Details for Your Staff</h4>
          <p>
            The staff members below are currently on approved leave of absence or have recently
            completed an approved leave of absence (with a return to duty date within the past 30
            days.)
          </p>
          <LeaveOfAbsenceTable leaveRequests={leaveRequests} />
        </>
      )}
    </>
  );
};

export { ReviewLeave };
