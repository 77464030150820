import { useState, useEffect } from 'react';

/** Filters an array, returning row if any object keys match the search term */
const filter = (data, filterString, columnFilters) => {
  const stringFilteredData = data.filter(row => {
    let includeRow = undefined;
    Object.values(row).forEach(field => {
      if (
        field
          .toString()
          .toLowerCase()
          .indexOf(filterString.toLowerCase()) >= 0
      )
        includeRow = row;
    });
    return includeRow;
  });

  const columnFilteredData = stringFilteredData.filter(row => {
    let includeRow = row;
    Object.entries(columnFilters).forEach(([column, filter]) => {
      if (row[column].toString().toLowerCase() !== filter.toLowerCase()) {
        includeRow = undefined;
      }
    });
    return includeRow;
  });
  return columnFilteredData;
};

/** Sorts an array based on a field and direction */
const sort = (data, field, ascending) => {
  return data.sort((a, b) => {
    const aValue = typeof a[field] === 'string' ? a[field].toLowerCase() : a[field];
    const bValue = typeof b[field] === 'string' ? b[field].toLowerCase() : b[field];
    if (ascending) {
      return aValue > bValue ? 1 : -1;
    } else {
      return bValue > aValue ? 1 : -1;
    }
  });
};

/** Allows data to be sorted and filter for display in data tables.
 * Accepts an array of JSON, and initial sort/filter settings.
 * Returns sorted data, along with functions to change sort and filter,
 * and an object representing sort settings.
 */
const useSort = ({ data, initialSort, ascending, initialFilter, initialColumnFilters }) => {
  const [sortedData, setSortedData] = useState([]);
  const [sortSettings, setSortSettings] = useState({
    field: initialSort,
    ascending: ascending === undefined ? true : ascending,
    filter: initialFilter || '',
    columnFilters: initialColumnFilters || {}
  });

  const changeSort = field => {
    const ascending = field === sortSettings.field ? !sortSettings.ascending : true;
    setSortSettings({
      field,
      ascending,
      filter: sortSettings.filter,
      columnFilters: sortSettings.columnFilters
    });
  };

  const changeFilter = searchTerm => {
    setSortSettings({
      field: sortSettings.field,
      ascending: sortSettings.ascending,
      filter: searchTerm,
      columnFilters: sortSettings.columnFilters
    });
  };

  const setColumnFilter = (column, searchTerm) => {
    const currentColumnFilters = sortSettings.columnFilters;
    if (searchTerm) {
      currentColumnFilters[column] = searchTerm;
    } else {
      delete currentColumnFilters[column];
    }
    setSortSettings({
      field: sortSettings.field,
      ascending: sortSettings.ascending,
      filter: sortSettings.filter,
      columnFilters: currentColumnFilters
    });
  };

  useEffect(() => {
    let isCancelled = false;
    if (data) {
      let copiedData = filter(data, sortSettings.filter, sortSettings.columnFilters);
      if (sortSettings.field) {
        copiedData = sort(copiedData, sortSettings.field, sortSettings.ascending);
      }
      if (!isCancelled) setSortedData(copiedData);
    }
    return () => (isCancelled = true);
  }, [data, sortSettings]);

  return { sortedData, changeSort, changeFilter, sortSettings, setColumnFilter };
};

export { useSort };
