import React from 'react';
import { Row, Col, ListGroup } from 'reactstrap';

import { MainMenuOption } from '../../Components/MainMenuOption';

const CentralDashboard = () => (
  <>
    <Row>
      <Col sm={{ size: 8 }} className="mx-auto text-center mb-4">
        <h1>OTC Central Office Resources</h1>
        <p className="lead">Additional applications and tools for central office staff members.</p>
      </Col>
    </Row>
    <Row>
      <Col md={{ size: 10, offset: 1 }}>
        <ListGroup>
          <MainMenuOption
            icon="comments"
            title="Performance Assessments"
            description="Review and submit performance assessments."
            link="https://octo.quickbase.com/db/bd8ib455k"
          />
          <MainMenuOption
            icon="user-md"
            title="Review Leave for Direct Reports"
            description="Review your staff members on leave."
            link="/central/review-leave"
          />
          <MainMenuOption
            icon="users"
            title="Screen Candidates"
            description="Screen and hire candidates for central office jobs."
            link="https://dcps.my.salesforce.com/"
          />
          <MainMenuOption
            icon="address-book"
            title="Central Office Directory"
            description="View a directory of central office employees"
            link="https://octo.quickbase.com/db/bkcxss6ir"
          />
          <MainMenuOption
            icon="school"
            title="Vacancy and Hiring Dashboards"
            description="Visit and analyze DCPS's school-based hiring dashboards."
            link="/rainbow"
          />
        </ListGroup>
      </Col>
    </Row>
  </>
);

export { CentralDashboard };
