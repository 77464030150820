import { useState, useEffect } from 'react';

import { fetchUserInfo, fetchRefresh, fetchLogout } from 'Libraries/signIn';
import { useInterval } from './useInterval';

/** Attempts to get a user object from localstorage */
const getUserFromStorage = () => {
  if (localStorage.getItem('user')) {
    try {
      return JSON.parse(localStorage.getItem('user'));
    } catch (e) {}
  }
  return undefined;
};

/** Calculates the number of minutes until an expiration time, rounding down */
const minutesLeft = expirationTime => {
  const msRemaining = new Date(expirationTime).getTime() - new Date().getTime();
  return Math.floor(msRemaining / 1000 / 60);
};

/** Share user object and time left (in mins) with the application, and three related functions:
 * signIn: signs in with email and password, sets user
 * refresh: extends time till expiration for current user
 * signOut: unsets user
 * getUserInfo: gets profile info
 */
const useUser = () => {
  const localUser = getUserFromStorage();
  const initialMinsLeft = localUser && minutesLeft(localUser.expires);
  const [user, setUser] = useState(initialMinsLeft > 0 && localUser);
  const [timeLeft, setTimeLeft] = useState(initialMinsLeft);

  // const signIn = async (email, password) => {
  //   const response = await fetchSignIn(email, password);
  //   if (response.user) {
  //     setUser(response.user);
  //     setTimeLeft(minutesLeft(response.user.expires));
  //     localStorage.setItem('user', JSON.stringify(response.user));
  //   }
  //   return response;
  // };

  const signIn = async () => {};

  const getUserInfo = async (ticket, email) => {
    const response = await fetchUserInfo(ticket, email);
    if (response.user) {
      setUser(response.user);
      setTimeLeft(minutesLeft(response.user.expires));
      console.log(response.user.expires);
      localStorage.setItem('user', JSON.stringify(response.user));
    }
    return response;
  };

  const refresh = async () => {
    if (user) {
      const updatedUser = await fetchRefresh(user);
      setUser(updatedUser);
      localStorage.setItem('user', JSON.stringify(updatedUser));
    }
  };

  const signOut = () => {
    setUser(undefined);
    setTimeLeft(-1);
    localStorage.removeItem('user');
    fetchLogout();
  };

  // Upon initialization, clear localStorage if the user's session has expired
  useEffect(() => {
    if (!user && localStorage.getItem('user')) localStorage.removeItem('user');
  }, [user]);

  /** If the user is signed in, check every second to see if the user in localstorage
   *  has changed (because the user signed out or refreshed on another tab.) */
  useInterval(
    () => {
      const localUser = getUserFromStorage();
      const timeLeftNow = minutesLeft(user.expires);
      if (timeLeftNow !== timeLeft) {
        setTimeLeft(timeLeftNow);
      }
      if (localUser.expires !== user.expires) {
        setUser(localUser);
      }
      if (localUser === undefined || timeLeftNow < 0) {
        setUser(undefined);
        localStorage.removeItem('user');
      }
    },
    user ? 1000 : null
  );
  return { user, timeLeft, getUserInfo, signIn, signOut, refresh };
};

export { useUser };
