import { Builder } from 'xml2js';

import { getUserTicket, buildRequestBody, parseResults } from './utilities';

/** Saves a record to Quickbase using the API_EditRecord or API_AddRecord API methods
 * Requires two arguments (tableID and data) and accepts a third recordID argument,
 * which will edit an existing record rather than add a new record. Returns JSON with either a
 * result or error object.
 */
const qbSubmit = async (data, tableID, recordID) => {
  
  console.log(data);
  console.log(tableID);
  console.log(recordID);
  const builder = new Builder({
    rootName: 'qdbapi',
    headless: true
  });
  
   console.log('after builder');

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/xml',
      'QUICKBASE-ACTION': recordID ? 'API_EditRecord' : 'API_AddRecord'
    },
    body: builder.buildObject([
      ...(await buildRequestBody(data)),
      { ticket: getUserTicket() || '' },
      { apptoken: 'cn8i52gvxu87tb3pgb735phn4e' },
      recordID && { rid: recordID }
    ])
  };
  
  console.log('after req otptions and right before fetch');
  

  const response = await parseResults(
    await (await fetch(`/quickbase-api/db/${tableID}`, requestOptions)).text()
  );
  
  console.log('after fetch');
  if (response.errcode !== '0') {
    console.log('err !==0');
    return {
      error: {
        code: response.errcode,
        text: response.errtext,
        details: response.errdetail
      }
    };
  }
  return {
    success: {
      rid: response.rid
    }
  };
};

export default qbSubmit;
