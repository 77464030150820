import React from 'react';
import { Spinner } from 'reactstrap';

const LoadingSpinner = ({ loading }) => {
  if (loading !== false)
    return (
      <div className="d-flex justify-content-center">
        <Spinner />
      </div>
    );
  return null;
};

export { LoadingSpinner };
