import React, { useState } from 'react';
import { Row, Col, Alert, Button, UncontrolledAlert } from 'reactstrap';

const TimeoutAlert = ({ timeLeft, refresh }) => {
  const [waiting, setWaiting] = useState(false);
  const handleSubmit = async () => {
    setWaiting(true);
    await refresh();
    setWaiting(false);
  };

  if (timeLeft === undefined || timeLeft > 15) return null;
  if (timeLeft >= 0) {
    let timeLeftMessage = `${timeLeft} minutes`;
    if (timeLeft === 1) timeLeftMessage = '1 minute';
    if (timeLeft === 0) timeLeftMessage = 'less than a minute';
    return (
      <Row className="sticky-top">
        <Col lg={{ size: 6, offset: 3 }}>
          <Alert color="danger">
            You will be signed out in {timeLeftMessage}.{' '}
            <Button disabled={waiting} onClick={handleSubmit} size="sm">
              Click here
            </Button>{' '}
            to stay logged in.
          </Alert>
        </Col>
      </Row>
    );
  }
  return (
    <Row className="sticky-top">
      <Col lg={{ size: 6, offset: 3 }}>
        <UncontrolledAlert color="danger">You have been signed out.</UncontrolledAlert>
      </Col>
    </Row>
  );
};

export { TimeoutAlert };
