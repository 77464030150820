import React from 'react';
import { NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';

import logo from './DCPS_Logo.png';

const NavBarLogo = () => (
  <NavbarBrand tag={Link} to="/">
    <img src={logo} className="d-inline-block align-top logo" alt="logo" />
    <div className="logo-top">DISTRICT OF COLUMBIA</div>
    <div className="logo-bottom">PUBLIC SCHOOLS</div>
    <div className="logo-small">Office of Employee Services</div>
  </NavbarBrand>
);

export { NavBarLogo };
