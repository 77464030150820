import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from 'Components';
import { Central, Home, Login, NotFound, StaffingRouter } from 'Screens';

import './icons';

const AfterSummerRouter = React.lazy(() => import('Screens/AfterSummerJobs'));
const Forms = React.lazy(() => import('Screens/Forms'));
const LeaveOfAbsence = React.lazy(() => import('Screens/LeaveOfAbsence'));
const RainbowRouter = React.lazy(() => import('Screens/Rainbow'));
const Resign = React.lazy(() => import('Screens/ResignRetire/Resign'));
const Retire = React.lazy(() => import('Screens/ResignRetire/Retire'));
const SchoolsRouter = React.lazy(() => import('Screens/Schools'));

const AppRouter = ({ user, signIn, signOut }) => (
  <Switch>
    <PublicRoute
      path="/login"
      component={Login}
      user={user}
      signIn={signIn}
      signOut={signOut}
      title="Sign In"
      exact
    />
    <PublicRoute path="/forms" title="Forms" component={Forms} user={user} />
    <PublicRoute
      path={['/afterschool', '/summerschool']}
      title="Afterschool/Summer School Jobs"
      component={AfterSummerRouter}
    />
    <PrivateRoute
      user={user}
      path="/leave-of-absence"
      title="Request a Leave of Absence"
      component={LeaveOfAbsence}
    />
    <PrivateRoute user={user} path="/resign" title="Resign from DCPS" component={Resign} />
    <PrivateRoute user={user} path="/retire" title="Retire with DCPS" component={Retire} />
    <PrivateRoute
      user={user}
      path="/central"
      title="Central Office Resources"
      component={Central}
    />
    <PrivateRoute
      user={user}
      path="/staffing"
      title="Staffing Team Resources"
      component={StaffingRouter}
    />
    <PrivateRoute
      user={user}
      path="/schools"
      title="Tools for School Leaders"
      component={SchoolsRouter}
    />
    <PrivateRoute
      user={user}
      path="/rainbow"
      title="Vacancy and Hiring Reports"
      component={RainbowRouter}
    />
    <PublicRoute path="/" exact component={Home} user={user} />
    <PublicRoute component={NotFound} title="Not Found" />
  </Switch>
);

export { AppRouter };
