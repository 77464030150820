import React from 'react';
import { FormGroup, Label, Button } from 'reactstrap';
import classnames from 'classnames';

class FileAttachmentField extends React.Component {
  render() {
    const field = this.props.field;
    const touched = this.props.form.touched;
    const errors = this.props.form.errors;
    const setFieldValue = this.props.form.setFieldValue;
    const classes = classnames('form-control', {
      'is-invalid': errors[field.name] && touched[field.name],
      'd-none': field.value
    });
    return (
      <FormGroup>
        <Label>
          <b>{this.props.label}</b>
        </Label>
        {field.value && (
          <div className="form-control-plaintext">
            <b>Selected:</b> {field.value.name}{' '}
            <Button
              color="link"
              onClick={event => {
                this.refs.form.value = '';
                setFieldValue(field.name, undefined);
              }}
            >
              Clear
            </Button>
          </div>
        )}
        <input
          style={{ height: 'auto' }}
          className={classes}
          type="file"
          onChange={event => {
            setFieldValue(field.name, event.currentTarget.files[0]);
          }}
          ref="form"
        />
        <div className="invalid-feedback">{errors[field.name]}</div>
      </FormGroup>
    );
  }
}

export { FileAttachmentField };
