import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PrivateRoute } from '../../Components';
import { NotFound } from '../';
import { CentralDashboard } from './CentralDashboard';
import { ReviewLeave } from './ReviewLeave';

const Central = props => (
  <Switch>
    <PrivateRoute user={props.user} path="/central" exact component={CentralDashboard} />
    <PrivateRoute user={props.user} path="/central/review-leave" component={ReviewLeave} />
    <Route component={NotFound} />
  </Switch>
);

export { Central };
