// const fetchSignIn = async (email, password) => {
//   const response = await (await fetch('/api/login', {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify({ email, password })
//   })).text();
//   if (response === 'Invalid username/password') {
//     return {
//       error: 'Invalid username/password'
//     };
//   }
//   return { user: JSON.parse(response) };
// };

// const fetchSignIn = async () => {
//   fetch('http://localhost:3001/login', {
//     credentials: 'include',
//     mode: 'no-cors'
//   });
// };

const fetchLogout = async () => {
  fetch('https://api.dcpsotc.org/logout', {
    credentials: 'include',
    mode: 'no-cors'
  });
};

// const fetchUserInfo = async () => {
// const response = await await fetch('http://localhost:3001/user', {
//   credentials: 'include',  https://api.dcpsotc.org
//   mode: 'no-cors',
//   method: 'GET',
//   headers: { 'Content-Type': 'application/json' }
//   });

//   console.log(response);
//   return { user: JSON.parse(response) };
// };

const fetchUserInfo = async (token, email) => {
  const response = JSON.parse(
    await (
      await fetch(`https://api.dcpsotc.org/user?ticket=${token}&email=${email}`, {
        method: 'GET',
        credentials: 'include',
        referrerPolicy: 'no-referrer-when-downgrade',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    ).text()
  );

  console.log(response);
  return { user: response };
};

const fetchRefresh = async user => {
  const response = JSON.parse(
    await (
      await fetch('/api/refresh', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'otc-csrf-protection': user.CSRFtoken
        }
      })
    ).text()
  );
  return Object.assign(user, {
    quickbaseTicket: response.quickbaseTicket,
    CSRFtoken: response.CSRFtoken,
    expires: response.expires
  });
};

export { fetchLogout, fetchUserInfo, fetchRefresh };
