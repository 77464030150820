import { useState, useEffect } from 'react';
import { Builder } from 'xml2js';

import { getUserTicket, parseResults } from 'Libraries/quickbase/utilities';

const qbFindVariable = async ({ tableID, varname }) => {
  const qdbapi = {
    apptoken: 'cn8i52gvxu87tb3pgb735phn4e',
    ticket: getUserTicket(),
    varname
  };

  const builder = new Builder();
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/xml',
      'QUICKBASE-ACTION': 'API_GetDBVar'
    },
    body: builder.buildObject({
      qdbapi
    })
  };

  const xmlResponse = await fetch(`/quickbase-api/db/${tableID}`, requestOptions);
  return (await parseResults(await xmlResponse.text())).value;
};

const useQBFindVariable = ({ tableID, variableName }) => {
  const [variable, setVariable] = useState(undefined);
  useEffect(() => {
    const fetchData = async () =>
      setVariable(
        await qbFindVariable({
          tableID,
          varname: variableName
        })
      );
    fetchData();
  }, [tableID, variableName]);
  return variable;
};

export { useQBFindVariable };
