import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label, FormText } from 'reactstrap';
import classnames from 'classnames';

const TextAreaField = ({ field, form: { touched, errors }, ...props }) => {
  const { t } = useTranslation('validation');
  const classes = classnames('form-control', {
    'is-invalid': errors[field.name] && touched[field.name]
  });
  return (
    <FormGroup>
      {props.label && (
        <Label>
          <b>{props.label}</b>
        </Label>
      )}
      <textarea {...field} {...props} className={classes} />
      <div className="invalid-feedback">{t(errors[field.name])}</div>
      {props.helptext && <FormText>{props.helptext}</FormText>}
    </FormGroup>
  );
};

export { TextAreaField };
