import React from 'react';

const AFSCMEText = ({ employee }) => {
  const formattedFTEString = Number(employee.fte).toLocaleString(undefined, {
    minimumFractionDigits: 1
  });
  const formattedSalary = Number(employee.new_salary).toLocaleString(undefined, {
    minimumFractionDigits: 2
  });
  return (
    <>
      <p>
        The District of Columbia Public Schools (DCPS) is extending an offer of employment to you as
        a 10-month {employee.title}, {formattedFTEString} FTE at {employee.school}, effective August
        19, 2019. Your pay rate is ${formattedSalary} per hour (Grade {employee.grade}, Step{' '}
        {employee.step}), paid bi-weekly. Your step progression will remain the same. You may
        contact Principal {employee.principal_last_name} at{' '}
        <a href={`mailto:${employee.principal_email}`}>{employee.principal_email}</a> if you have
        any questions regarding your assignment.
      </p>
      <p>
        This is a union position covered under the Agreement between DCPS and the American
        Federation of Federal, State, and Municipal Employees (AFSCME), District Council 20, Local
        2921, AFL-CIO.
      </p>
      <p>
        This offer is contingent upon your remaining in good standing with DCPS, which includes:
      </p>
      <ul>
        <li>Retaining your current position subsequent to SY18-19 staff reductions;</li>
        <li>
          Receiving a final IMPACT score for SY18-19 that qualifies you for continued employment
          with DCPS;
        </li>
        <li>Adhering to DCPS rules and applicable standards of professional conduct.</li>
      </ul>
      <p>
        Please respond to this offer by indicating your response below. You must accept this offer
        no later than Tuesday, April 30, 2019. Failure to respond to this offer by Tuesday, April
        30, 2019, will be considered a rejection of this offer. Please consult the Frequently Asked
        Questions (FAQs) and 10-month calendar for more information about what to expect upon
        accepting or rejecting this offer.
      </p>
      <p>
        If you decide to decline this offer, you will be subject to reduction due to the elimination
        of your 12-month position. Reduced employees will receive notifications with next steps in
        mid-May. If you are unable to secure a position before your effective date and you have been
        employed with DCPS continuously for at least 12 months prior to your effective date, you may
        be eligible for a severance payment. You will be notified of your eligibility for severance
        by mid-August.
      </p>
      <p>
        We hope that you will decide to accept this 10-month position with the District of Columbia
        Public Schools.
      </p>
    </>
  );
};
export { AFSCMEText };
