import React from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-date-picker';
import classnames from 'classnames';

import { FormGroup, Label } from 'reactstrap';
import './date.css';

const DateField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  ...props
}) => {
  const handleOnChange = date => {
    setFieldValue(field.name, date);
    setFieldTouched(field.name, true);
  };

  const classes = classnames('form-control', {
    'is-invalid': errors[field.name] && touched[field.name]
  });
  const { t } = useTranslation('validation');

  return (
    <FormGroup>
      <Label>
        <b>{props.label}</b>
      </Label>
      <br />
      <DatePicker
        onChange={handleOnChange}
        value={field.value}
        maxDate={props.maxDate}
        minDate={props.minDate}
        className={classes}
      />
      {errors[field.name] && touched[field.name] && (
        <div className="invalid-feedback d-block">{t(errors[field.name])}</div>
      )}
    </FormGroup>
  );
};

export { DateField };
