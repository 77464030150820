import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SortableColumn = ({ width, name, label, sortSettings, changeSort }) => {
  return (
    <th width={width} onClick={() => changeSort(name)} style={{ cursor: 'pointer' }}>
      <span style={{ color: '#007bff' }}>{label}</span>
      {sortSettings.field === name && (
        <>
          {' '}
          {sortSettings.ascending ? (
            <FontAwesomeIcon icon="caret-up" />
          ) : (
            <FontAwesomeIcon icon="caret-down" />
          )}
        </>
      )}
    </th>
  );
};

export { SortableColumn };
