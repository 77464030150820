import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { LoadingSpinner } from 'Components';

import { EYForm } from './EYForm';
import { useQBQuery } from 'Hooks';
const ExtendedToTraditional = () => {
  const employee = useQBQuery({
    tableID: 'bpgmb8w48',
    query: '{32.TV._curuser_}',
    clist: 'a',
    findOne: true
  });

  return (
    <Row>
      <Col lg={{ size: 8, offset: 2 }}>
        <Card>
          <CardBody>
            <LoadingSpinner className="text-center mt-5" loading={employee === undefined} />
            {employee && <EYForm employee={employee} />}
            {employee === null && (
              <p>
                Uh oh! We can't find the extended year transition details associated with your email
                address. Please reach out to us at{' '}
                <a href="mailto:dcps.hrquickbase@dc.gov">dcps.hrquickbase@dc.gov</a> and we'll get
                to work on a fix!
              </p>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
export { ExtendedToTraditional };
