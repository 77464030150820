import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PrivateRoute } from '../../Components';
import { NotFound } from '../';
import { ExtendedToTraditional } from './ExtendedToTraditional';

const StaffingRouter = props => (
  <Switch>
    <PrivateRoute
      user={props.user}
      path="/staffing/extended-to-traditional"
      exact
      component={ExtendedToTraditional}
    />
    <Route component={NotFound} />
  </Switch>
);

export { StaffingRouter };
