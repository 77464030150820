import React from 'react';
import { Route } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

export const PublicRoute = ({ component: Component, title, render, path, exact, ...props }) => {
  // If passed a title, set it using DocumentTitle component; otherwise construct a fragment.
  let TitleComponent = React.Fragment;
  let titleProps = {};
  if (title) {
    TitleComponent = DocumentTitle;
    titleProps = { title: `OTC > ${title}` };
  }

  // If we don't explicitly receive a render function, set one based on component.
  if (render === undefined) render = routerProps => <Component {...routerProps} {...props} />;
  return (
    <TitleComponent {...titleProps}>
      <Route exact={exact} path={path} {...{ render }} />
    </TitleComponent>
  );
};
