import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <Row>
          <Col sm={{ size: 8, offset: 2 }}>
            <Card>
              <CardBody>
                <h5 className="pb-2 mt-4 mb-2 border-bottom">Something Went Wrong...</h5>
                <p>
                  Uh oh! Something went wrong, and this page crashed. Please try refreshing the
                  page. If this error persists, please send an email to{' '}
                  <a href="mailto:dcps.hrquickbase@dc.gov">dcps.hrquickbase@dc.gov</a> indicating
                  that the application crashed on the {window.location.pathname} page and we'll get
                  back to you within a business day.
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
