import React from 'react';
import { Link } from 'react-router-dom';
import { ListGroupItem, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MainMenuOption = props => {
  const external = props.link.startsWith('http');
  return (
    <ListGroupItem>
      <Row>
        <Col xs="2" lg="1">
          {props.icon && <FontAwesomeIcon icon={props.icon} size="3x" />}
        </Col>
        <Col>
          <h6>
            {external ? (
              <a href={props.link} target="_blank" rel="noopener noreferrer">
                {props.title} <FontAwesomeIcon icon="external-link-alt" />
              </a>
            ) : (
              <Link to={props.link}>{props.title}</Link>
            )}
          </h6>
          <p>{props.description}</p>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

export { MainMenuOption };
