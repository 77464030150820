import { useState, useEffect } from 'react';

import qbFind from 'Libraries/quickbase/qb-find';

/** React hooks-style implementation of API_DoQuery.
 * Data is undefined while fetching, and becomes:
 * null if no matching data is found;
 * an object if the findOne option is used;
 * a list otherwise
 */
const useQBQuery = ({ wait, tableID, qid, query, clist, variables, findOne }) => {
  const [data, setData] = useState(undefined);

  // Variables may be undefined, a string, or an array. Here we convert them to a JSON string
  // because useEffect equality comparisons for Arrays are shallow.
  const variableString = variables ? JSON.stringify(variables) : undefined;

  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      const result = await qbFind({
        tableID,
        qid,
        query,
        clist,
        variables: variableString && JSON.parse(variableString),
        findOne
      });
      if (!didCancel) setData(result);
    };
    if (!wait) {
      fetchData();
    }
    return () => (didCancel = true);
  }, [clist, findOne, qid, query, tableID, variableString, wait]);
  return data;
};

export { useQBQuery };
