import { useState } from 'react';

import { qbSubmit as qbAPISubmit } from 'Libraries/quickbase';

/** Adds or updates a Quickbase record, and returns either a save
 * or error object after request is made.
 */
const useQBSubmit = () => {
  const [saveResults, setSaveResults] = useState({});

  const qbSubmit = async (data, tableID, recordID) => {
    
    setSaveResults(await qbAPISubmit(data, tableID, recordID));
  };

  return { qbSubmit, qbError: saveResults.error, qbSuccess: saveResults.success };
};

export { useQBSubmit };
