import React from 'react';
import { FormGroup, Label } from 'reactstrap';

const PlainTextField = props => {
  // Expects a label prop, and displays the children.
  return (
    <FormGroup>
      <Label>
        <b>{props.label}</b>
      </Label>
      <p>{props.children}</p>
    </FormGroup>
  );
};

export { PlainTextField };
