import React from 'react';

const WTUText = ({ employee }) => {
  const formattedFTEString = Number(employee.fte).toLocaleString(undefined, {
    minimumFractionDigits: 1
  });
  const formattedSalary = Number(employee.new_salary).toLocaleString(undefined, {
    minimumFractionDigits: 2
  });
  return (
    <>
      <p>
        The District of Columbia Public Schools (DCPS) is extending an offer of employment to you as
        a 10-month {employee.title}, {formattedFTEString} FTE at {employee.school}, effective August
        19, 2019. When annualized, your salary equates to ${formattedSalary} (Grade {employee.grade}
        , Step {employee.step}), paid bi-weekly. Your step progression will remain the same. You may
        contact Principal {employee.principal_last_name} at{' '}
        <a href={`mailto:${employee.principal_email}`}>{employee.principal_email}</a> if you have
        any questions regarding your assignment.
      </p>
      <p>
        This 10-month position is covered under the Agreement between DCPS and the Washington
        Teachers' Union (WTU), Local 6, American Federation of Teachers, AFL-CIO. If you accept this
        offer, you will be eligible for the benefits provided to 10-month teachers under the terms
        of this agreement.
      </p>
      <p>
        This offer is contingent upon your remaining in good standing with DCPS, which includes:
      </p>
      <ul>
        <li>Retaining your current position subsequent to SY18-19 staff reductions;</li>
        <li>
          Receiving a final IMPACT score for SY18-19 that qualifies you for continued employment
          with DCPS;
        </li>
        <li>Satisfying all licensure obligations required of you;</li>
        <li>Adhering to DCPS rules and applicable standards of professional conduct.</li>
      </ul>
      <p>
        Per the Agreement between DCPS and the WTU, if you voluntarily separate from this position
        prior to the end of the school year, you may be subject to a monetary penalty.
      </p>
      <p>
        Please respond to this offer by indicating your response below. You must accept this offer
        no later than Tuesday, April 30, 2019. Failure to respond to this offer by Tuesday, April
        30, 2019, will be considered a rejection of this offer. Please consult the Frequently Asked
        Questions (FAQs) and 10-month calendar for more information about what to expect upon
        accepting or rejecting this offer.
      </p>
      <p>
        If you decide to decline this offer, pursuant to Article 4 of the WTU contract, you will be
        excessed from your 10-month position. Excessed employees will receive notifications with
        next steps in mid-May. If you are unable to secure another position at DCPS, you will be
        afforded excessing options as required in the WTU contract if you are eligible for such
        options. If you are not able to secure a position at other DCPS schools, and you are not
        eligible for options, you will be separated from employment with DCPS.
      </p>
      <p>
        We hope that you will decide to accept this 10-month position with the District of Columbia
        Public Schools.
      </p>
    </>
  );
};
export { WTUText };
