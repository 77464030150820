import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Row, Col, Button } from 'reactstrap';

const SubmitArea = props => {
  const { t } = useTranslation();
  const hidePrevious = props.hidePrevious;
  const nextTitle = props.nextTitle || t('next');

  return (
    <>
      <FormGroup>
        <Row>
          <Col xs="6">
            {!hidePrevious && (
              <Button onClick={() => props.previous()} tabIndex="1">
                {t('previous')}
              </Button>
            )}
          </Col>
          <Col xs="6">
            <Button className="float-right" type="submit" disabled={props.isSubmitting}>
              {nextTitle}
            </Button>
          </Col>
        </Row>
        {!props.isSubmitting &&
          props.submitCount > 0 &&
          props.errors &&
          Object.keys(props.errors).length !== 0 && (
            <p className="text-danger small">{t('required-fields-missing')}</p>
          )}
      </FormGroup>
    </>
  );
};

export { SubmitArea };
