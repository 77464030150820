import React from 'react';
import { Alert } from 'reactstrap';
import { LoadingSpinner } from './LoadingSpinner';

/** Shows standardized loading message/not-found messages or returns data to child props  */
const DataOrErrors = ({ data, notFoundMessage, render }) => {
  if (data === undefined) {
    return <LoadingSpinner />;
  }
  if (data === null) {
    if (notFoundMessage) return notFoundMessage;
    return (
      <Alert color="warning">
        Hmm, we couldn't find any matching records. If you believe this is in error, please send an
        email to <a href="mailto:dcps.hrquickbase@k12.dc.gov">dcps.hrquickbase@k12.dc.gov</a> indicating
        that you weren't able to see any records on the {window.location.pathname} page and we'll
        get back to you within a business day.
      </Alert>
    );
  }
  return render(data);
};

export { DataOrErrors };
