import React from 'react';
import {
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';

const UserNav = ({ closeNavbar, user, signIn, signOut }) => {
  const onSignOut = () => {
    signOut();
    closeNavbar();
  };

  const signedIn = user;
  let username = '';

  if (signedIn) {
    username = user.firstName ? `${user.firstName} ${user.lastName}` : user.email;
  }

  //  const handleSubmit = async ({ email, password }, actions) => {
  //   const response = await signIn(email, password);
  //   if (response.user) {
  //     if (nextURL) {
  //       history.push(nextURL);
  //     } else {
  //       history.push('/');
  //     }
  //   } else {
  //     setSaveErrors(response.error);
  //     actions.setSubmitting(false);
  //   }
  // };

  return (
    <Nav className="ml-auto" navbar>
      {signedIn ? (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            {username}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag={Link} onClick={onSignOut} to="/">
              Sign Out
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        <NavItem>
          <a href="https://octo.quickbase.com/db/bh9v8fxr6?a=dbpage&pageID=97">Sign In</a>
        </NavItem>
      )}
    </Nav>
  );
};

export { UserNav };
