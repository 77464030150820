import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { LoadingSpinner } from 'Components';

import { useQBQuery } from 'Hooks';

const LeaveEntitlementModal = props => {
  // Expects selectedRecordID prop
  const leaveRequest = useQBQuery({
    tableID: 'bi6vn2cfb',
    qid: 46,
    variables: props.selectedRecordID,
    findOne: true
  });

  return (
    <Modal isOpen={true} toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle}>
        Leave of Absence Details: {leaveRequest && leaveRequest.full_name}
      </ModalHeader>
      <ModalBody>
        <>
          <LoadingSpinner className="text-center" loading={leaveRequest === undefined} />
          {leaveRequest && (
            <>
              <p>
                The chart below provides an explanation of the employee's leave entitlement.{' '}
                {leaveRequest.full_name} (Employee ID: {leaveRequest.employee_id}) requested to use
                paid leave in a specific sequence, which is listed below from top (first) to bottom
                (last.)
              </p>

              <table className="table table-sm">
                <thead>
                  <tr>
                    <th width="25%">Leave Type</th>
                    <th width="25%">
                      Hours Balance as of {leaveRequest.date_created.toLocaleDateString('en-US')}
                    </th>
                    <th width="25%"># of Hours Requested</th>
                    <th width="25%"># of Hours Approved</th>
                  </tr>
                </thead>
                <tbody>
                  {leaveRequest.leave_designation_choices___1st_choice !== '' && (
                    <tr>
                      <td>{leaveRequest.leave_designation_choices___1st_choice}</td>
                      <td>
                        {leaveRequest.leave_designation_choices___1st_choice__hours_available}
                      </td>
                      <td>
                        {leaveRequest.leave_designation_choices___1st_choice__hours_requested}
                      </td>
                      <td>{leaveRequest.leave_designation_choices___1st_choice__hours_approved}</td>
                    </tr>
                  )}
                  {leaveRequest.leave_designation_choices___2nd_choice !== '' && (
                    <tr>
                      <td>{leaveRequest.leave_designation_choices___2nd_choice}</td>
                      <td>
                        {leaveRequest.leave_designation_choices___2nd_choice__hours_available}
                      </td>
                      <td>
                        {leaveRequest.leave_designation_choices___2nd_choice__hours_requested}
                      </td>
                      <td>{leaveRequest.leave_designation_choices___2nd_choice__hours_approved}</td>
                    </tr>
                  )}
                  {leaveRequest.leave_designation_choices___3rd_choice !== '' && (
                    <tr>
                      <td>{leaveRequest.leave_designation_choices___3rd_choice}</td>
                      <td>
                        {leaveRequest.leave_designation_choices___3rd_choice__hours_available}
                      </td>
                      <td>
                        {leaveRequest.leave_designation_choices___3rd_choice__hours_requested}
                      </td>
                      <td>{leaveRequest.leave_designation_choices___3rd_choice__hours_approved}</td>
                    </tr>
                  )}
                  {leaveRequest.leave_designation_choices___4th_choice !== '' && (
                    <tr>
                      <td>{leaveRequest.leave_designation_choices___4th_choice}</td>
                      <td>
                        {leaveRequest.leave_designation_choices___4th_choice__hours_available}
                      </td>
                      <td>
                        {leaveRequest.leave_designation_choices___4th_choice__hours_requested}
                      </td>
                      <td>{leaveRequest.leave_designation_choices___4th_choice__hours_approved}</td>
                    </tr>
                  )}
                  {leaveRequest.leave_designation_choices___5th_choice !== '' && (
                    <tr>
                      <td>{leaveRequest.leave_designation_choices___5th_choice}</td>
                      <td>
                        {leaveRequest.leave_designation_choices___5th_choice__hours_available}
                      </td>
                      <td>
                        {leaveRequest.leave_designation_choices___5th_choice__hours_requested}
                      </td>
                      <td>{leaveRequest.leave_designation_choices___5th_choice__hours_approved}</td>
                    </tr>
                  )}
                  {leaveRequest.leave_designation_choices___6th_choice !== '' && (
                    <tr>
                      <td>{leaveRequest.leave_designation_choices___6th_choice}</td>
                      <td>
                        {leaveRequest.leave_designation_choices___6th_choice__hours_available}
                      </td>
                      <td>
                        {leaveRequest.leave_designation_choices___6th_choice__hours_requested}
                      </td>
                      <td>{leaveRequest.leave_designation_choices___6th_choice__hours_approved}</td>
                    </tr>
                  )}
                  {leaveRequest.leave_designation_choices___7th_choice !== '' && (
                    <tr>
                      <td>{leaveRequest.leave_designation_choices___7th_choice}</td>
                      <td>
                        {leaveRequest.leave_designation_choices___7th_choice__hours_available}
                      </td>
                      <td>
                        {leaveRequest.leave_designation_choices___7th_choice__hours_requested}
                      </td>
                      <td>{leaveRequest.leave_designation_choices___7th_choice__hours_approved}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Leave Without Pay</td>
                    <td>N/A</td>
                    <td>
                      {leaveRequest.leave_designation_choices___unpaid_leave__hours_requested}
                    </td>
                    <td>{leaveRequest.leave_designation_choices___unpaid_leave__hours_approved}</td>
                  </tr>
                </tbody>
              </table>

              {leaveRequest.applying_for_disability_ === 'Yes' && (
                <p>
                  Please be advised that this employee has applied for short-term disability through
                  The Standard. If The Standard approves the short-term disability claim, the
                  employee will receive 66 2/3% of their bi-weekly pay directly from the Standard.
                  The remaining 33 1/3% of pay can be paid in the event that the employee has a
                  balance of leave. Note: total pay should not exceed 100% of their earnings. The
                  employee should forward a copy of the notification to their supervisor within
                  three days of receipt, and indicate how they wish to be compensated for the
                  remaining 33 1/3% of their pay, to account for 100% of salary. Note that the
                  employee may choose to be on Leave Without Pay for the remaining 33 1/3% of
                  salary. However, if the employee has a balance of leave to use to earn the 33 1/3%
                  of salary that will not be paid by The Standard, the timekeeper must code on the
                  employee’s timesheet no more than 13 hours of leave per week, as designated by the
                  employee.
                </p>
              )}

              <p>
                Note: If the employee’s leave is Intermittent they are required to notify you and
                their supervisor of each absence taken during the approved leave period to ensure
                that all entitlement is accurately applied. In addition, Paid Family and Medical
                Leave (PFML) hours must be used in whole day increments.
              </p>

              <p>
                Note for PFL users only: Your team member’s approval for PFL will be forwarded to
                the Office of Pay and Retirement Services (OPRS) to load into your team member’s
                PeopleSoft leave account. This process typically takes 10-15 business days from the
                date of this notification. In the interim your timekeeper can enter your team
                member’s available sick/annual leave hours or leave without pay. Once the PFL hours
                become available in PeopleSoft, the timekeeper must submit a timesheet correction to
                reimburse any sick/annual leave used during your team member’s approved PFL dates
                and/or pay for any days entered as leave without pay.
              </p>

              <p>
                The correct TRC code to use once the PFL hours have been loaded to your team
                member’s PeopleSoft record is PFLT. Please be sure to work with your timekeeper
                regarding PFL entry and completing timesheet corrections, where applicable.
              </p>
            </>
          )}
        </>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export { LeaveEntitlementModal };
