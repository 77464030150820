import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, FormText, Label } from 'reactstrap';
import classnames from 'classnames';

const TextField = ({ field, form: { touched, errors }, ...props }) => {
  const classes = classnames('form-control', {
    'is-invalid': errors[field.name] && touched[field.name]
  });

  const errorsBelow = props.errors === 'bottom';
  const { t } = useTranslation('validation');

  return (
    <FormGroup>
      {errorsBelow ? (
        <Label>
          <b>{props.label}</b>
        </Label>
      ) : (
        <Row>
          <Col xs="5">
            <Label>
              <b>{props.label}</b>
            </Label>
          </Col>
          <Col xs="7">
            <span className="text-danger small">
              {errors[field.name] && touched[field.name] && t(errors[field.name])}
            </span>
          </Col>
        </Row>
      )}
      <input type={props.type || 'text'} {...field} {...props} className={classes} />
      {props.helptext && <FormText>{props.helptext}</FormText>}
      {errorsBelow && (
        <div className="text-danger small">
          {errors[field.name] && touched[field.name] && t(errors[field.name])}
        </div>
      )}
    </FormGroup>
  );
};

export { TextField };
