import { Builder } from 'xml2js';

import { getUserTicket, forceToArray, parseResults } from './utilities';

/** Queries Quickbase using the options variable, and returns a formatted list of objects.
 * Ticket and tableID are required elements.
 * Either qid or query must be provided as well, but not both.
 * clist must be included if using query
 * variables are optional if using qid
 * findOne (boolean) is optional and indicates the response should be an object rather than list of objects.
 */
const qbFind = async ({ tableID, qid, query, clist, variables, findOne }) => {
  const qdbapi = {
    apptoken: 'cn8i52gvxu87tb3pgb735phn4e',
    ticket: getUserTicket() || '',
    fmt: 'structured',
    qid: qid,
    query: query,
    clist: clist
  };

  if (variables) {
    const variablesList = forceToArray(variables);
    qdbapi.nv = variablesList.length;
    variablesList.forEach((variable, index) => {
      qdbapi[`v${index}`] = variable;
    });
  }

  if (findOne) qdbapi.options = 'num-1';

  const builder = new Builder();
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/xml',
      'QUICKBASE-ACTION': 'API_DoQuery'
    },
    body: builder.buildObject({
      qdbapi
    })
  };

  const xmlResponse = await fetch(`/quickbase-api/db/${tableID}`, requestOptions);
  const response = await parseResults(await xmlResponse.text());

  if (response.table === undefined || response.table.records.record === undefined) return null;
  const fieldMetadata = forceToArray(response.table.fields.field);
  const records = forceToArray(response.table.records.record);

  const fieldMap = {};
  fieldMetadata.forEach(field => {
    fieldMap[field.$.id] = {
      name: field.label.replace(/\W/g, '_').toLowerCase(),
      baseType: field.$.base_type,
      fieldType: field.$.field_type
    };
  });

  const responseList = [];
  records.forEach(record => {
    const JSONRecord = {};
    forceToArray(record.f).forEach(field => {
      const { name, baseType, fieldType } = fieldMap[field.$.id];
      let value = field._;
      if (value === undefined) {
        JSONRecord[name] = '';
      } else {
        if (baseType === 'float') value = Number(field._) || 0;
        if (baseType === 'bool') value = field._ === '1';
        if (fieldType === 'timestamp') value = new Date(Number(field._));
        if (fieldType === 'date') {
          value = new Date(Number(field._)).toLocaleDateString('en-US', { timeZone: 'UTC' });
        }
        JSONRecord[name] = value;
      }
    });
    responseList.push(JSONRecord);
  });

  if (findOne) return responseList[0];
  return responseList;
};

export default qbFind;
