import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { CardTitle, FormGroup, Button } from 'reactstrap';
import * as Yup from 'yup';

import { qbSubmit } from 'Libraries/quickbase';
import { SelectField, TextField } from 'Components';

import { AFSCMEText } from './AFSCMEText';
import { WTUText } from './WTUText';

const schema = Yup.object().shape({
  decision: Yup.string().required('Required'),
  electronic_signature: Yup.string().required('Required')
});

const EYForm = ({ employee }) => {
  const [saveResults, setSaveResults] = useState({});
  const formSubmitted = employee.signature_timestamp !== '' || saveResults.success;

  const handleSubmit = async (values, actions) => {
    setSaveResults(
      await qbSubmit(
        {
          ...values,
          signature_timestamp: new Date()
        },
        'bpgmb8w48',
        employee.record_id_
      )
    );
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        decision: employee.decision,
        electronic_signature: employee.electronic_signature
      }}
      validationSchema={schema}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      render={({ isSubmitting, submitCount, errors }) => (
        <Form noValidate>
          <CardTitle tag="h5">Extended Year Transition Offer</CardTitle>

          <p>Dear {employee.employee_name},</p>
          {employee.union === 'WTU' && <WTUText employee={employee} />}
          {employee.union === 'AFSCME' && <AFSCMEText employee={employee} />}
          <p>Sincerely,</p>
          <img
            src="/MD_Signature.jpeg"
            alt="Michael DeFabbo signature"
            style={{ maxWidth: '150px' }}
          />
          <p>Mike DeFabbo</p>
          <p>Director, Strategic Staffing</p>
          <hr />

          <Field
            component={SelectField}
            name="decision"
            label="Given this information, do you accept this offer of 10-month employment?"
            errors="bottom"
            disabled={formSubmitted}
          >
            <option />
            <option>Accept</option>
            <option>Decline</option>
          </Field>

          <Field
            component={TextField}
            name="electronic_signature"
            label="Electronic Signature"
            disabled={formSubmitted}
          />
          <FormGroup>
            {!formSubmitted && (
              <Button type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            )}
            {!isSubmitting && submitCount > 0 && Object.keys(errors).length > 0 && (
              <p className="text-danger">
                There are some missing responses, so this form could not be submitted. Please review
                and fix them then try to submit again.
              </p>
            )}
          </FormGroup>
          {saveResults.error && (
            <p>
              Uh oh! We ran into an error: {saveResults.error.details} Your decision was not saved.
              Please reach out to{' '}
              <a href="mailto:dcps.hrquickbase@dc.gov">dcps.hrquickbase@dc.gov</a> and we'll help
              resolve this issue.
            </p>
          )}
          {saveResults.success && <p>Thanks! Your response has been saved.</p>}
          {employee.signature_timestamp !== '' && (
            <p>
              You responded to this offer on {employee.signature_timestamp.toLocaleDateString()}.
            </p>
          )}
        </Form>
      )}
    />
  );
};

export { EYForm };
