import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import classnames from 'classnames';

const RadioField = ({ field, form: { touched, errors }, hideErrors, ...props }) => {
  const classes = classnames('form-check-input', {
    'is-invalid': errors[field.name] && touched[field.name]
  });
  return (
    <FormGroup check>
      <input
        type="radio"
        {...field}
        {...props}
        className={classes}
        id={`${field.name}-${props.value}`}
        checked={field.value === props.value}
      />
      {!hideErrors && <span className="invalid-feedback">{errors[field.name]}</span>}
      {props.label && (
        <Label check for={`${field.name}-${props.value}`}>
          <b>{props.label}</b>
        </Label>
      )}
    </FormGroup>
  );
};

export { RadioField };
