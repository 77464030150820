import React, { useEffect, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import ReactGA from 'react-ga';
import DocumentTitle from 'react-document-title';

import { useUser } from 'Hooks/useUser';
import { Navigation, ErrorBoundary, Footer, TimeoutAlert, LoadingSpinner } from 'Components';

import { AppRouter } from './AppRouter';
import { UserProvider } from './user-context';
import './icons';

ReactGA.initialize('UA-47084917-3');

const App = ({ history }) => {
  const { user, timeLeft, refresh, getUserInfo, signIn, signOut } = useUser();

  useEffect(() => {
    if (user && timeLeft <= 60) refresh();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [history.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <UserProvider value={user}>
      <DocumentTitle title="DCPS Office of Talent and Culture">
        <Container>
          <Navigation user={user} signIn={signIn} signOut={signOut} getUserInfo={getUserInfo} />
          <TimeoutAlert timeLeft={timeLeft} refresh={refresh} />
          <ErrorBoundary>
            <Suspense fallback={<LoadingSpinner />}>
              <AppRouter user={user} signIn={signIn} signOut={signOut} />
            </Suspense>
          </ErrorBoundary>
          <Footer />
        </Container>
      </DocumentTitle>
    </UserProvider>
  );
  // }
};

export default withRouter(App);
