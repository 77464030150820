import React from 'react';

import './footer.css';

const Footer = () => (
  <div className="container">
    <div className="site-footer">
      <div className="wrapper d-none d-md-block">
        1200 First Street, NE&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Washington, DC
        20002&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;T
        202.442.5885&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;F
        202.442.5026&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
        <a href="https://dcps.dc.gov">dcps.dc.gov</a>
      </div>
      <div className="wrapper d-xs-block d-md-none">
        1200 First Street, NE&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Washington, DC 20002
        <br />T 202.442.5885&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;F 202.442.5026
        <br />
        <a href="https://dcps.dc.gov">dcps.dc.gov</a>
      </div>
    </div>
  </div>
);

export { Footer };
